<template>
  <el-dialog
    append-to-body
    width="600px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
    >
      <el-form-item prop='app_url' label='上传安装包' v-if="form.data.type == 1">
        <el-upload
          v-loading="uploadLoading"
          class="upload-demo"
          drag
          :limit="1"
          action=""
          :headers="{token:token}"
          :file-list="fileList"
          :on-remove="removeFile"
          :multiple="false"
          :http-request="httpRequest"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </el-form-item>
      <el-form-item prop='version' label='版本号'>
        <el-input v-model="form.data.version" placeholder="请输入版本号" maxlength="20" clearable></el-input>
      </el-form-item>
      <el-form-item prop='content' label='更新内容'>
        <el-input v-model="form.data.content" placeholder="请输入更新内容" type="textarea" :autosize="{ minRows: 4, maxRows: 8}"></el-input>
      </el-form-item>
      <el-form-item prop='is_forced' label="强制更新">
        <el-radio-group v-model="form.data.is_forced">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="2">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop='type' label="设备类型">
        <el-radio-group v-model="form.data.type">
          <el-radio :label="1">安卓(android)</el-radio>
          <el-radio :label="2">苹果(ios)</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>

    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img v-if="dialogVisible" width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'BannerEdit',
    data() {
      return {
        token: '',
        visible: false,
        isChange: false,
        loading: false,
        uploadLoading: false,
        form: {
          loading: false,
          data: {
            id: '',
            app_url: '',
            version: '',
            content: '',
            type: 1,
            is_forced: 0,
            client_type: 2, // app类型
          },
          rules: {
            app_url: [{ required: true, message:'请上传安装包', trigger: 'change' }],
            version: [{ required: true, message:'请输入版本号', trigger: 'change' }],
            content: [{ required: true, message:'请输入更新内容', trigger: 'change' }],
            type: [{ required: true}],
          }
        },
        dialogVisible: false,
        dialogImageUrl: [],
        fileList: [],
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        console.log(row)
        this.isChange = true
        this.$http.get('admin/flex_app/info', {params: {id:row.id}}).then(res => {
          if(res.code === 1) {
            common.deepClone(this.form.data, res.data)
            this.form.data.app_url = res.data.app_url.all_path
            this.fileList = [{
              name: res.data.app_url.all_path
            }]
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            if(this.form.data.type == 2 ) {
              this.form.data.app_url = '';
            }
            let apiUrl = !this.isChange ? '/admin/flex_app/add' : '/admin/flex_app/edit'
            this.form.loading = true;
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      removeFile() {
        this.fileList = [];
      },
      httpRequest(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.uploadLoading = true;
        this.$http.post('/common/qiniu/uploadFile', {formData, type:'upload'}).then(res => {
          this.form.data.app_url = res.data.all_path_url;
          this.$refs.elFormDom.clearValidate();
          this.uploadLoading = false;
        })
      }
    }
  }
</script>


<style scoped>
.avatar-uploader-login .el-icon-plus {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.avatar-uploader-login .avatar-uploader-icon{
  line-height: 100px !important;
}
.avatar-uploader-login .avatar {
  width: 100px;
  height: 100px;
}
.avatar-uploader-image .el-icon-plus {
  width: 234px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}
.avatar-uploader-image .avatar {
  width: 234px;
  height: 100px;
}
.avatar-uploader-image .avatar-uploader-icon{
  line-height: 100px !important;
}
.el-upload__tip {
  color: #f56c6c;
  margin-top: 0;
}
</style>